import React from 'react';

const HelpContentStaticCN = () => {
  return (
    <div className="awsui-util-m-l" data-awsui-column-layout-root="true">
      <div>
        <span data-testid="help-content">This help section is temporarily unavailable. Please check back in Q2 2021.</span>
      </div>
    </div>
  );
}

export default HelpContentStaticCN;