import React from 'react';
import HelpContentStatic from './HelpContentStatic';
import HelpContentStaticCN from './HelpContentStaticCN';
import HelpContentStaticMVP from './HelpContentStaticMVP';
import { useArgoContext } from '@awsscm/argo-core';

export default function Help() {
  const context = useArgoContext();
  const region = context ? context.region : 'us-west-2';

  switch (region) {
    case 'cn-northwest-1':
      return <HelpContentStaticCN />;
    case 'us-iso-east-1':
    case 'us-isob-east-1':
      return <HelpContentStaticMVP />;
    default:
      return <HelpContentStatic />;
  }
}