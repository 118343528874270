import React from 'react';
import HelpPanel from '@amzn/awsui-components-react-v3/polaris/help-panel';
import USER_DISPLAY_STRINGS from '../../../resources/user_display_strings';

const HelpContentStatic = () => {
  return (
    <HelpPanel
      header={<h3>{USER_DISPLAY_STRINGS.needHelp}</h3>}
      >
        <ul>
          <li><a href="mailto: aws-scm-ux@amazon.com">Contact Us</a></li>
          <li><a href="https://t.corp.amazon.com/create/templates/2dfa697f-c937-41e7-98f2-b4b2c8736510" target="_blank" rel="noreferrer">Report a Bug</a></li>
          <li><a href="https://w.amazon.com/bin/view/AWS_SCM/UXTeam/Argo" target="_blank" rel="noreferrer">About Argo</a></li>
        </ul>
    </HelpPanel>
  );
}

export default HelpContentStatic;