import React from 'react';

const HelpContentStaticMVP = () => {
  return (
    <div data-testid="help-content" className="awsui-util-m-l" data-awsui-column-layout-root="true">
      <div>
        <h3>Need Help?</h3>
        <ul>
          <li><a href="mailto: aws-scm-ux@amazon.com">Contact Us</a></li>
          <li><a href="https://w.amazon.com/bin/view/AWS_SCM/UXTeam/Argo" target="_blank" rel="noreferrer">About Argo</a></li>
        </ul>
      </div>
    </div>
  );
}

export default HelpContentStaticMVP;