import * as React from 'react';
import { Route, Switch } from 'react-router-dom';
import LandingPage from '../LandingPage';
import { RouteLoadDurationProvider } from '@awsscm/argo-core/argo/utils/metrics/ArgoRouteLoadDurationContext';

export default function MainPage() {
  return (
    <div className="content">
      <RouteLoadDurationProvider>
        <React.Suspense fallback={<div>Loading...</div>}>
          <Switch>
            <Route path={'/'} exact component={LandingPage} />
            <Route path={'/landing-page'} exact component={LandingPage} />
          </Switch>
        </React.Suspense>
      </RouteLoadDurationProvider>
    </div>
  );
}
